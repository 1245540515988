import React, { useState, useEffect, useCallback } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Project from "../components/project"
import CurrentProject from "../components/currentProject"

import useJsonData from "../hooks/use-jsonData"

import { SecMainHead } from "../styles/styledComponent"

const projectFilterList = [
  {
    name: "All",
    value: "all",
    active: true,
    display: true,
  },
  {
    name: "Angular",
    value: "angular",
    active: false,
    display: true,
  },
  {
    name: "Capstone Projects",
    value: "cp",
    active: false,
    display: false,
  },
  {
    name: "JavaScript",
    value: "js",
    active: false,
    display: false,
  },
  {
    name: "Machine Learning",
    value: "ml",
    active: false,
    display: true,
  },
  {
    name: "MERN Stack",
    value: "mern-stack",
    active: false,
    display: true,
  },
  {
    name: "Native Mobile Application",
    value: "nativeApp",
    active: false,
    display: true,
  },
  {
    name: "Python",
    value: "python",
    active: false,
    display: true,
  },
  {
    name: "ReactJS",
    value: "react",
    active: false,
    display: true,
  },
]

const PortfolioPage = () => {
  const jsonData = useJsonData()

  const [state, setState] = useState({
    projectFilterList,
    projectListDetail: jsonData.projects.sort((a, b) => {
      return b.data.uniqueId - a.data.uniqueId
    }),
    currentProject: null,
    modalActive: false,
  })

  const filterByTag = i => {
    // filter the projects to show, based on selected tag
    let tempState = state
    tempState.projectFilterList.forEach(filter => (filter.active = false))
    tempState.projectListDetail.forEach(project => {
      if (
        project.data.tags.indexOf(tempState.projectFilterList[i].value) !== -1
      ) {
        project.data.display = true
      } else project.data.display = false
    })
    tempState.projectFilterList[i].active = true
    setState({ ...tempState })
  }

  const openCurrentProject = uniqueId => {
    let tempState = { ...state }
    tempState.currentProject = tempState.projectListDetail.filter(
      project => project.data.uniqueId === uniqueId
    )[0].data
    setState({ ...tempState, modalActive: true })
  }

  const toggleModal = useCallback(() => {
    let tempState = { ...state }
    setState({ ...tempState, modalActive: !state.modalActive })
  }, [state])

  useEffect(() => {
    document.addEventListener("keydown", event => {
      if (event.keyCode === 27 && state.modalActive) {
        toggleModal()
      }
    })
  }, [state.modalActive, toggleModal])

  return (
    <Layout>
      <SEO
        title="Rishav Pandey | Portfolio"
        description="Rishav Pandey Portfolio"
      />
      <div className="portfolio-page">
        <section className="sec-type-1">
          <div className="container">
            <div className="main-wrapper">
              <div className="sec-heading">
                <SecMainHead>My Works and Projects</SecMainHead>
              </div>
              <div className="sec-desc">
                <p>
                  I've always been fascinated by the project-based learning
                  approach. Passively learning facts and reciting them out of
                  context is no longer sufficient to prepare me for solving
                  real-world problems. Working with projects has helped me a lot
                  in developing various skills likewise teamwork,
                  problem-solving, research gathering, time management,
                  information synthesizing, implementing new tools and
                  technologies. I've personally felt these all are very
                  essential in becoming a good software engineer and develop
                  skills.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-type-2">
          <div className="container">
            <div className="main-wrapper">
              <div className="sec-heading">
                <SecMainHead>
                  Happy spending my time to this projects
                </SecMainHead>
              </div>
              <div className="sec-desc">
                <div className="project-filter-div">
                  {state.projectFilterList.map((item, i) => (
                    <button
                      key={i}
                      className={
                        item.display
                          ? item.active
                            ? "filter-div active"
                            : "filter-div"
                          : "filter-div d-none"
                      }
                      onClick={filterByTag.bind(null, i)}
                    >
                      <span>{item.name}</span>
                    </button>
                  ))}
                </div>
                <div className="project-list-div grid-view md-2-col-grid lg-3-col-grid">
                  {state.projectListDetail.map(item => (
                    <div
                      key={item.data.uniqueId}
                      className={item.data.display ? "d-block" : "d-none"}
                    >
                      <Project
                        project={item}
                        openCurrentProject={openCurrentProject}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <CurrentProject
            modalActive={state.modalActive}
            currentProject={state.currentProject}
            toggleModal={toggleModal}
          />
        </section>
      </div>
    </Layout>
  )
}

export default PortfolioPage
