import React from "react"
import Img from "gatsby-image"
import { FaProjectDiagram } from "react-icons/fa"

import scssVariables from "../styles/sass/global.scss"

import { ThemeButton } from "../styles/styledComponent"

const Project = props => {
  return (
    <div className="project-div">
      <figure>
        <Img
          fluid={props.project.data.thumbnail.sharp.fluid}
          alt={props.project.data.name + " thumbnail image"}
          fadeIn={true}
        />
        <figcaption className="figcaption">
          <FaProjectDiagram
            className="fa-2x"
            style={{ marginBottom: "0.5rem" }}
          />
          <h3 className="project-title">{props.project.data.name}</h3>
          <div>
            <span className="short-desc">{props.project.data.shortDesc}</span>
          </div>
          <ThemeButton
            borderColor={scssVariables.fontColorType1}
            onClick={props.openCurrentProject.bind(
              null,
              props.project.data.uniqueId
            )}
          >
            View Details
          </ThemeButton>
        </figcaption>
      </figure>
    </div>
  )
}

export default Project
