import React from "react"
import Img from "gatsby-image"
import { MdClose } from "react-icons/md"

const CurrentProject = ({ modalActive, currentProject, toggleModal }) => {
  return (
    <div className={`modal ${modalActive ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <section className="modal-card-body sec-type-2">
          <div className="modal-close-btn">
            <button className="plain-btn" onClick={toggleModal}>
              <MdClose />
            </button>
          </div>
          {currentProject ? (
            <>
              <div className="project-info">
                <div className="project-title">
                  <h2>{currentProject.name}</h2>
                </div>
                <div className="project-desc">
                  <p>{currentProject.desc}</p>
                  <p>
                    Status:
                    <span className="custom-text">
                      &nbsp;{currentProject.status}
                    </span>
                  </p>
                  {currentProject.githubRepo ? (
                    <p>
                      GitHub repository for this project can be viewed by&nbsp;
                      <a
                        href={currentProject.githubRepo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <em>clicking here</em>
                      </a>
                    </p>
                  ) : null}
                </div>
                <div className="tech-tags columns">
                  {currentProject.technologyUsed.map((item, i) => (
                    <div className="d-inline-block tags" key={i}>
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
                <div className="collaborators">
                  <h5>
                    {currentProject.collaborators.length > 1
                      ? "Collaborators:-"
                      : "Collaborator:-"}
                  </h5>
                  {currentProject.collaborators.map((collaborator, i) =>
                    collaborator.website ? (
                      <span key={i}>
                        <a
                          href={collaborator.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {collaborator.name}
                          {i !== currentProject.collaborators.length - 1
                            ? ", "
                            : ""}
                        </a>
                      </span>
                    ) : (
                      <span key={i}>
                        {collaborator.name}
                        {i !== currentProject.collaborators.length - 1
                          ? ", "
                          : ""}
                      </span>
                    )
                  )}
                </div>
                {currentProject.url ? (
                  <div className="demo-button">
                    <a
                      href={currentProject.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-btn"
                    >
                      Live Demo
                    </a>
                  </div>
                ) : null}
              </div>

              <div className="project-screenshots">
                <h3>Screenshots-</h3>
                <div className="screenshots grid-view lg-2-col-grid">
                  {currentProject.images.map((image, index) => (
                    <div className="screenshot" key={index}>
                      <Img
                        fluid={image.value.sharp.fluid}
                        alt={image.alt}
                        fadeIn={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}
        </section>
      </div>
    </div>
  )
}

export default CurrentProject
